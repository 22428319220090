import React from 'react'
import ReactDOM from 'react-dom'

export default class Root extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      plans: props.plans,
      selectPlans: {image: null, movie: null, illustration: null, result: null},
      selectMonth: props.current_plan ? props.current_plan.month : 1,
      currentPlan: props.current_plan,
      nextUrl: props.next_url,
    }
  }

  onClickMonth(value) {
    if (value == this.state.selectMonth) return;
    this.setState({selectMonth: value});
    this.setState({selectPlans: {image: null, movie: null, illustration: null, result: null}})
  }

  onClickSquare(plan) {
    const selectPlans = this.state.selectPlans
    if (!plan.selectable) return;
    if (plan.type == 'image') {
      selectPlans.image = selectPlans.image == plan ? null : plan;
      this.setState({selectPlans: this.resultPlan(selectPlans)});
    } else if (plan.type == 'movie') {
      selectPlans.movie = selectPlans.movie == plan ? null : plan;
      this.setState({selectPlans: this.resultPlan(selectPlans)});
    } else if (plan.type == 'illustration') {
      selectPlans.illustration = selectPlans.illustration == plan ? null : plan;
      this.setState({selectPlans: this.resultPlan(selectPlans)});
    }
  }

  resultPlan(selectPlans) {
    const resultPlan = this.state.plans.find((plan) => {
      return plan.image_limit == (selectPlans.image == null ? 0 : selectPlans.image.image_limit)
        && plan.video_limit == (selectPlans.movie == null ? 0 : selectPlans.movie.video_limit)
        && plan.illustration_limit == (selectPlans.illustration == null ? 0 : selectPlans.illustration.illustration_limit)
        && plan.month == this.state.selectMonth
    });
    return {image: selectPlans.image, movie: selectPlans.movie, illustration: selectPlans.illustration, result: resultPlan}
  }

  render() {
    return (
      <div>
        <MonthList selectMonth={this.state.selectMonth} onClick={(v) => this.onClickMonth(v)} />
        <Table selectMonth={this.state.selectMonth} selectPlans={this.state.selectPlans} plans={this.state.plans} onClick={(plan) => this.onClickSquare(plan)}/>
        <Description />
        <div className='plans__border'></div>
        <CulcResult selectPlans={this.state.selectPlans} />
        <Button plan={this.state.selectPlans.result} nextUrl={this.state.nextUrl} />
      </div>
    )
  }
}

class MonthList extends React.Component {
  constructor() {
    super();
  }

  render() {
    const months = [
      {month: 1, discount: 0},
      {month: 3, discount: 5},
      {month: 6, discount: 10},
      {month: 12, discount: 25},
    ];

    return (
      <ul className="plans__month-list">
        {months.map((month, index) => {
          return (
            <li key={index} className={month.month == this.props.selectMonth ? 'plans__month month-select' : 'plans__month'} onClick={() => this.props.onClick(month.month)} >
              {month.month}ヶ月
              {month.discount > 0 && <div className="plans__month-discount">{month.discount}%OFF</div>}
            </li>
          )
        })}
      </ul>
    )
  }
}

class Table extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <table className="plans__table">
        <tbody>
          <tr>
            <th></th>
            <td><div className="plans__table-type">画像</div></td>
            <td><div className="plans__table-type">動画</div></td>
            <td><div className="plans__table-type">イラスト</div></td>
          </tr>
          <TableRow selectPlans={this.props.selectPlans} plans={this.props.plans} month={this.props.selectMonth} onClick={(p) => this.props.onClick(p)} size='s'  />
          <TableRow selectPlans={this.props.selectPlans} plans={this.props.plans} month={this.props.selectMonth} onClick={(p) => this.props.onClick(p)} size='m' discount={5} />
          <TableRow selectPlans={this.props.selectPlans} plans={this.props.plans} month={this.props.selectMonth} onClick={(p) => this.props.onClick(p)} size='l' discount={10} />
        </tbody>
      </table>
    )
  }
}

class TableRow extends React.Component {
  constructor() {
    super();
  }

  find_plan(type) {
    return this.props.plans.find((plan) => plan.type == type && plan.size == this.props.size && plan.month == this.props.month);
  }

  render() {
    return (
      <tr>
        <th>
          <div className="plans__table-pack">
            <div className="plans__table-size">{this.props.size.toUpperCase()}</div>
            <div className="plans__table-plan">プラン</div>
            {this.props.discount && <div className="plans__pack-discount">{this.props.discount}%OFF</div>}
          </div>
        </th>
        <td><PlanSquare plan={this.find_plan('image')} selectPlans={this.props.selectPlans} onClick={(p) => this.props.onClick(p)} /></td>
        <td><PlanSquare plan={this.find_plan('movie')} selectPlans={this.props.selectPlans} onClick={(p) => this.props.onClick(p)} /></td>
        <td><PlanSquare plan={this.find_plan('illustration')} selectPlans={this.props.selectPlans} onClick={(p) => this.props.onClick(p)} /></td>
      </tr>
    )
  }
}

class PlanSquare extends React.Component {
  constructor() {
    super();
  }

  render() {
    const limit = [
        { type: 'image', unit: '枚', limit: this.props.plan.image_limit },
        { type: 'movie', unit: '本', limit: this.props.plan.video_limit },
        { type: 'illustration', unit: '枚', limit: this.props.plan.illustration_limit },
      ]
        .filter(object => object.limit > 0)
        .map(object => `${object.limit}${object.unit}`)
        .join(' + ');

    const selected = (() => {
      if (this.props.plan.type == 'image') {
        return this.props.selectPlans.image == this.props.plan
      } else if (this.props.plan.type == 'movie') {
        return this.props.selectPlans.movie == this.props.plan
      } else if (this.props.plan.type == 'illustration') {
        return this.props.selectPlans.illustration == this.props.plan
      }
      return false
    })();

    return (
      <div className={selected ? 'plans__table-detail plan-select' : 'plans__table-detail'} disabled={!this.props.plan.selectable} onClick={() => this.props.onClick(this.props.plan)}>
        <div className="plans__table-limit">{limit}</div>
        <div className="plans__table-discount">{
          this.props.plan.discounts
            .map((discount, index) => <span key={index} className={`plans__table-discount_${discount.type}`}>{discount.value}</span>)
            .reduce((lhs, rhs) => lhs == '' ? rhs : [lhs, (<span key={`${this.props.plan.id}-${lhs.key}`} className='plans__table-multiplication'>×</span>), rhs], '')
          }
        </div>
        <div className="plans__table-charge">{this.props.plan.charge.toLocaleString()}円</div>
      </div>
    )
  }
}

class CulcResult extends React.Component {
  constructor() {
    super();
  }

  planResultSquare(plan) {
    if (plan) {
      return (
        <div className='plans__result-content'>
          <div className='plans__result-name'>{plan.name}</div>
          <PlanSquare plan={plan} selectPlans={{image: null, movie: null, result: null}} onClick={() => {}} />
        </div>
      )
    } else {
      return (
        <div className='plans__result-content'>
          <div className='plans__result-name'></div>
          <div className="plans__result-box"></div>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="plans__result">
        <div className="plans__result-row">
          {this.planResultSquare(this.props.selectPlans.image)}
          <div className='plans__result-content'>
            <div className='plans__result-name'></div>
            <div className="plans__result-operator">+</div>
          </div>
          {this.planResultSquare(this.props.selectPlans.movie)}
          <div className='plans__result-content'>
            <div className='plans__result-name'></div>
            <div className="plans__result-operator">+</div>
          </div>
          {this.planResultSquare(this.props.selectPlans.illustration)}
        </div>
        <div className="plans__result-row">
          <div className='plans__result-content'>
            <div className='plans__result-name'></div>
            <div className="plans__result-operator">=</div>
          </div>
          {this.planResultSquare(this.props.selectPlans.result)}
        </div>
      </div>
    )
  }
}

const Button = props => (
  <form action={props.nextUrl} method="get">
    <div className="plans__button-div">
      <input type="hidden" name="plan[id]" id="plan_id" value={props.plan ? props.plan.id : 0}></input>
      <input type="submit" className='btn plans__button' value="プラン契約手続きへ" disabled={props.plan == null || !props.plan.changeable} />
    </div>
  </form>
)

const Description = props => (
  <div className="plans__description">
    <ul className="plans__description-list">
      <li>画像・動画・イラストのいずれかを一緒に購入すると合計金額からさらに５％OFFとなります(セット割)</li>
      <li>3ヶ月、6ヶ月、12ヶ月の長期プランはそれぞれ期間に応じて割引が適応されます</li>
      <li>画像・動画・イラストを全て購入しても５％以上の割引にはなりません</li>
    </ul>
  </div>
)
