import React from 'react'
import ReactDOM from 'react-dom'

export default class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
			path: props.path,
			plans: props.plans,
			user_id: props.user_id,
			current_plan: props.current_plan,
			plan: props.plans[0],
			csrfParams: props.csrf_params,
			csrfToken: props.csrf_token
		}
		this.onChange = this.onChange.bind(this);
	}

	onChange(e){
    this.setState({ plan: this.state.plans.find(p => p.id == e.target.value) });
	}

	info(plan) {
		const plan_change_type = (_ => {
			switch (plan.plan_change_type) {
				case 'same':
					return '現在のプラン';
				case 'renewal':
					return '次回のプランから適用';
				case 'upgrade':
					return '即時適用';
				default:
					return '';
			}
		})();

		return (
			<div>
				<div>適用タイミング：{plan_change_type}</div>
				{plan.plan_change_type == 'upgrade' && <div>差額：{this.state.plan.charge - this.state.current_plan.charge}円</div>}
			</div>
		)
	}

	onClick() {
		confirm('プラン変更を確定します');
	}

  render() {
		console.log(this.state.plan);
    return (
			<div>
				{this.state.plan && this.info(this.state.plan)}
				<form action={this.state.path} method='post'>
					<input type='hidden' name={this.state.csrfParams} value={this.state.csrfToken} />
					<input type='hidden' name='user_id' value={this.state.user_id} />
					<select name="plan_id" value={this.state.plan && this.state.plan.id} onChange={this.onChange}>
						{ this.state.plans.map (plan => <option key={plan.id} value={plan.id}>{plan.name}</option>) }
					</select>
					<button className='btn btn-primary' type='submit' onClick={this.onClick}>確定する</button>
				</form>
			</div>
    );
  }
}